/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  name: yup.string().required(ERROR_MESSAGES.required),
  type: yup.string().required(ERROR_MESSAGES.required),
  photoSessionName: yup.string().nullable(),
  skus: yup.array().of(yup.string()),
});

const DEFAULT_VALUES = {
  name: '',
  type: '',
  photoSessionName: '',
  skus: [],
};

enum BUNDLE_TYPE {
  OTHER = 'other',
  PHOTO_SESSION = 'photo_session',
  STYLIST = 'stylist',
  SET = 'set',
  SIMILARITY = 'similarity',
  GLOBAL_HERO_LOOKS = 'global_hero_looks',
  KEY_LOOKS = 'key_looks',
  LOOKBOOK = 'lookbook',
}

const BUNDLE_TYPE_DICT: {
  [K: string]: string;
} = {
  [BUNDLE_TYPE.OTHER]: 'Inny',
  [BUNDLE_TYPE.PHOTO_SESSION]: 'Sesja fotograficzna',
  [BUNDLE_TYPE.STYLIST]: 'Opinia stylistki',
  [BUNDLE_TYPE.SET]: 'Zestaw ze zdjęcia',
  [BUNDLE_TYPE.SIMILARITY]: 'Produkty podobne wg filtrów',
  [BUNDLE_TYPE.GLOBAL_HERO_LOOKS]: 'Global Hero Looks',
  [BUNDLE_TYPE.KEY_LOOKS]: 'Key Looks',
  [BUNDLE_TYPE.LOOKBOOK]: 'Lookbook',
};

const BUNDLE_TYPE_OPTIONS = Object.values(BUNDLE_TYPE).map((type) => ({
  label: BUNDLE_TYPE_DICT[type],
  value: type,
}));

export {
  BODY_SCHEMA,
  DEFAULT_VALUES,
  BUNDLE_TYPE,
  BUNDLE_TYPE_DICT,
  BUNDLE_TYPE_OPTIONS,
};
