import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useQueryParams from 'utils/useQueryParams';
import { NAME, PAGE, SKU, TYPE } from 'pages/ProductBundles/Listing/constants';
import FilterBox from 'components/FilterBox';
import TagInput from 'components/Form/TagInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { BUNDLE_TYPE_OPTIONS } from 'pages/ProductBundles/Form/constants';

function FilterBody() {
  const { search, setSearch } = useQueryParams();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [SKU]: search.get(SKU)?.split(',') || [],
      [NAME]: search.get(NAME) || '',
      [TYPE]: search.get(TYPE) || '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { sku, name, type } = data;

    if (isEmpty(sku)) search.delete(SKU);
    else search.set(SKU, sku?.join(','));

    if (isEmpty(name)) search.delete(NAME);
    else search.set(NAME, name);

    if (isEmpty(type)) search.delete(TYPE);
    else search.set(TYPE, type);

    search.set(PAGE, '1');
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} id="filter-form">
        <FilterBox
          filterForm={
            <>
              <TagInput name={SKU} placeholder="SKU (oddzielone przecinkiem)" />
              <Input name={NAME} placeholder="Nazwa" />
              <Select
                name={TYPE}
                placeholder="Typ"
                options={BUNDLE_TYPE_OPTIONS}
                isClearable
              />
            </>
          }
        />
      </form>
    </FormProvider>
  );
}

export default FilterBody;
