import { useEffect, useMemo, useState } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce, isEmpty } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import {
  BUNDLE_TYPE,
  BUNDLE_TYPE_OPTIONS,
} from 'pages/ProductBundles/Form/constants';
import TagInput from 'components/Form/TagInput';
import PhotoSessionSelect from 'pages/ProductBundles/Form/components/PhotoSessionSelect';
import ProductsPreview from 'pages/ProductBundles/Form/components/ProductsPreview';
import Card from 'components/Card';

function FormBody({ isLoading }: FormBodyProps) {
  const [products, setProducts] = useState<string[]>([]);
  const { control } = useFormContext();
  const [type, skus] = useWatch({
    control,
    name: ['type', 'skus'],
  });

  const updateProducts = useMemo(
    () =>
      debounce((ids) => {
        setProducts(ids);
      }, 700),
    [],
  );

  useEffect(() => {
    updateProducts(skus);
  }, [skus, updateProducts]);

  const isPhotoSession = type === BUNDLE_TYPE.PHOTO_SESSION;

  return (
    <Stack w="100%" spacing={4} mb={20}>
      {isLoading && <LoadingSpinner />}
      <Card>
        <Stack spacing={4}>
          <Input name="name" label="Nazwa" placeholder="Wpisz nazwę" />
          <Text variant="h4" fontWeight={600}>
            Wybierz typ paczki
          </Text>
          <HStack spacing={2} wrap="wrap">
            {BUNDLE_TYPE_OPTIONS.map((bundleType) => (
              <RadioBoxControl
                id={`bundle-type-${bundleType.value}`}
                key={bundleType.value}
                label={bundleType.label}
                name="type"
                value={bundleType.value}
              />
            ))}
          </HStack>
          {isPhotoSession && <PhotoSessionSelect />}
          <Text variant="h4" fontWeight={600}>
            Produkty
          </Text>
          <TagInput
            name="skus"
            label="SKU"
            placeholder="SKU (oddzielone przecinkiem)"
          />
          {!isEmpty(skus) && isPhotoSession && (
            <>
              <Text variant="h4" fontWeight={600}>
                Podgląd produktów
              </Text>
              <ProductsPreview skus={products} />
            </>
          )}
        </Stack>
      </Card>
    </Stack>
  );
}

export default FormBody;
